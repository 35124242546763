import { notification } from "antd";
import heic2any from "heic2any";

export const resizeImage = (file: File, maxWidth: number, maxHeight: number, quality: number): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    if (file.type === 'image/heic' || file.type === 'heic' || file.type === '') {
      heic2any({
        blob: file,
        toType: "image/JPEG"
      })
        .then((result) => {
          const convertedBlob = Array.isArray(result) ? result[0] : result;
          adjustImageSize(convertedBlob, maxWidth, maxHeight, quality)
            .then((resizedBlob) => {
              resolve(resizedBlob);
            })
            .catch((error) => {
              const errorMessage = 'Lỗi xảy ra khi điều chỉnh kích thước ảnh';
              reject(new Error(errorMessage));
              notification.error({
                description: errorMessage,
                message: 'Thông báo'
              });
            });
        })
        .catch((error) => {
          const errorMessage = 'Lỗi xảy ra khi chuyển đổi ảnh';
          reject(new Error(errorMessage));
          notification.error({
            description: errorMessage,
            message: 'Thông báo'
          });
        });
    } else {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const scale = Math.min(maxWidth / img.width, maxHeight / img.height);

        canvas.width = img.width * scale;
        canvas.height = img.height * scale;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            if (reader.result) {
              resolve(new Blob([reader.result as ArrayBuffer]));
            } else {
              reject(new Error('Lỗi xảy ra khi điều chỉnh kích thước ảnh'));
            }
          };
          canvas.toBlob((blob) => {
            reader.readAsArrayBuffer(blob);
          }, 'image/jpeg', quality);
        }, 'image/jpeg', quality);
      };

      img.onerror = () => {
        const errorMessage = 'Lỗi xảy ra khi tải ảnh';
        reject(new Error(errorMessage));
        notification.error({
          description: errorMessage,
          message: 'Thông báo'
        });
      };
    }
  });
};

const adjustImageSize = (blob: Blob, maxWidth: number, maxHeight: number, quality: number): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(blob);

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const scale = Math.min(maxWidth / img.width, maxHeight / img.height);

      canvas.width = img.width * scale;
      canvas.height = img.height * scale;

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((resizedBlob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            resolve(new Blob([reader.result as ArrayBuffer]));
          } else {
            reject(new Error('Lỗi xảy ra khi điều chỉnh kích thước ảnh'));
          }
        };
        canvas.toBlob((resizedBlob) => {
          reader.readAsArrayBuffer(resizedBlob);
        }, 'image/jpeg', quality);
      }, 'image/jpeg', quality);
    };

    img.onerror = () => {
      const errorMessage = 'Lỗi xảy ra khi điều chỉnh kích thước ảnh';
      reject(new Error(errorMessage));
      notification.error({
        description: errorMessage,
        message: 'Thông báo'
      });
    };
  });
};

