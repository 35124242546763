import { useEffect, useState } from 'react'
import StyleType from '../StyleType'

type Props = {
  index: number
  styleData: any
  user: any
  getInfo
}

export default function HairStyle({ index, styleData, user, getInfo }: Props) {
  const [userTypeCollectionInfo, setUserTypeCollectionInfo] = useState([])

  useEffect(() => {
    if (styleData) {
      setUserTypeCollectionInfo(styleData.hairStyleDetails)
    }
  }, [styleData])

  return (
    <div className='style'>
      <div className='style__header'>
        <div className='style__header--title'>{styleData?.name}</div>
      </div>
      <div className='style__body'>
        {userTypeCollectionInfo?.filter((item: any) => !item.isDelete).map((item: any, index: any) => (
          <StyleType
            key={index}
            typeData={item}
            StyleIndex={index}
            user={user}
            styleData={styleData}
            getInfo={getInfo}
          />
        ))}
      </div>
    </div>
  )
}
