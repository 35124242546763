import { DeleteOutlined, ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons'
import { Button, Modal, Spin, notification } from 'antd'
import { useState } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { resizeImage } from '../../compoment/resizeImage'

type Props = {
  index: number
  status: number
  defaultImage: any
  onFileChange: (url) => void
  onDelete: () => void
  image: string
}

export default function ChooseImage({ status, onFileChange, onDelete, image }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0]
    if (file) {
      try {
        setLoading(true)
        const resizedImage = await resizeImage(file, 1024, 1024, 0.5)
        await uploadImage(resizedImage)
      } catch (error) {
        console.error('Error resizing image:', error)
        setLoading(false)
      }
    } else {
      console.log('ko có ảnh');
      notification.error({
        description: 'Vui lòng chọn ảnh',
        message: 'Thông báo'
      });
    }
  }

  const uploadImage = async (resizedImage: Blob) => {
    const AccessToken = Cookies.get('AccessToken') || ''
    if (loading) return
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('photo', resizedImage, 'resized.jpg')
      const response = await axios.post(`${process.env.REACT_APP_POST_STAFF_IMAGE}/staff/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${AccessToken}`
        }
      });
      if (response.data.data.image_url) onFileChange(response.data.data.image_url);
    } catch (error) {
      notification.error({
        description: 'Có lỗi xảy ra, vui lòng thử lại sau',
        message: 'Thông báo'
      });
      throw error;
    }
    setLoading(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    onDelete();
  };

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <Spin spinning={loading}>
      <div className='box-image aspect-[3/4]  col-span-1'>
        <Modal
          open={isModalOpen}
          footer={null}
          centered
          closeIcon={true}
          width={'280px'}
          onCancel={() => setIsModalOpen(false)}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '10px'
            }}
          >
            <ExclamationCircleFilled style={{ color: '#FF8F1F', fontSize: '64px' }} />
            <span style={{ fontWeight: '700', lineHeight: '21px' }}>Xác nhận xóa ảnh vừa chọn?</span>
            {status === 2 ? <div>Trạng thái sẽ chuyển về “Chờ duyệt”</div> : <div></div>}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
            <Button onClick={() => handleCancel()} style={{ marginBottom: '10px' }}>
              Không xóa
            </Button>
            <Button onClick={() => handleOk()} type='primary'>
              Xác nhận xóa
            </Button>
          </div>
        </Modal>
        {image ? (
          <div className='image-cover'>
            <img src={image} alt='Selected' />
            <div onClick={() => setIsModalOpen(true)} className='button-delete' aria-hidden='true'>
              <DeleteOutlined className='icon' />
            </div>
          </div>
        ) : (
          <label className='image-cover custom-file-label'>
            <span className='button-content'>
              <PlusOutlined className='icon' />
            </span>
            <input className='custom-file-input' type='file' accept='image/*' onChange={handleFileInputChange} />
          </label>
        )}
      </div>
    </Spin>
  );
}
