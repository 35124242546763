import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import userSlice from './slices/userSlice'
import permissionSlice from './slices/permissionSlice'
// import staffSlice from '../pages/staff/staffSlice'
import stylist from '../pages/stylist/stylistSlice'
import storage from 'redux-persist/lib/storage'
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'

const permissionPersistConfig = {
  key: 'permission',
  storage: storage,
  whitelist: ['listRoutingPermission']
}

const rootReducer = combineReducers({
  user: userSlice,
  permission: persistReducer(permissionPersistConfig, permissionSlice),
  // staff: staffSlice,
  stylist
})

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export type RootState = ReturnType<typeof store.getState>
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export type StoreType = typeof store
export default store
