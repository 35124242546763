import './../style.scss'
import { useEffect, useState } from 'react'
import ChooseImage from './chooseImage'
import { Button, Spin, notification } from 'antd'
import { getUploadedInfo, postNewUpdateRequest } from '../../api'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { fetchUser } from 'redux/slices/userSlice'
import { hairImgStatus } from './status'

const initListImage = [{ value: '' }, { value: '' }, { value: '' }, { value: '' }]

export default function StylistSelectHair() {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(0)
  const [defaultImage, setDefaultImage] = useState(initListImage)
  const [loadingInfo, setLoadingInfo] = useState(false)
  const user = useAppSelector((state) => state.user)
  const [buttonText, setButtonText] = useState('')
  const [disabledButton, setDisabledButton] = useState(true)
  const dispatch = useAppDispatch()
  const [isChange, setIsChange] = useState(false)

  const handleFileChange = (index, url: string) => {
    const images = [...defaultImage]
    images[index].value = url
    setDefaultImage(images)
    setIsChange(true)
  }

  const handleDelete = (value: any) => {
    setDefaultImage(defaultImage.map((item) => ({ value: item.value === value ? '' : item.value })))
    setStatus(1)
  }
  const handleUpload = async () => {
    if (loading) return
    try {
      setLoading(true)
      const response = await postNewUpdateRequest({
        data: defaultImage,
        type: 1
      })
      if (response) {
        setDisabledButton(true)
        setIsChange(false)
      }
      notification.success({
        message: 'Thông báo',
        description: 'Upload ảnh thành công, chờ duyệt ảnh'
      })
    } catch (error) {
      notification.error({
        message: 'Thông báo',
        description: 'Có lỗi xảy ra vui lòng thử lại sau!'
      })
      console.log(error, 'Lỗi không gửi được dữ liệu')
    }
    setLoading(false)
  }
  const getInfo = async () => {
    try {
      setLoadingInfo(true)
      const response = await getUploadedInfo(1)
      if (response?.data) {
        const jsonString = response.data.data
        const jsonArray = JSON.parse(jsonString)
        setStatus(response.data?.statusId)
        setDefaultImage(jsonArray)
      }
    } catch (error) {
      console.log('Lỗi không lấy được dữ liệu')
      setStatus(1)
    }
    setLoadingInfo(false)
  }

  useEffect(() => {
    if (user.userInfo.listSalon.length) {
      getInfo()
      return
    }
  }, [user])

  useEffect(() => {
    const isAnyEmpty = defaultImage.some((item) => !item.value)
    if (!isAnyEmpty && isChange) {
      setDisabledButton(false)
      return
    } else {
      setDisabledButton(true)
      return
    }
  }, [defaultImage])

  useEffect(() => {
    switch (status) {
      case 1:
        setButtonText('UPLOAD - CHỜ DUYỆT')
        setDisabledButton(true)
        break
      case 2:
        setButtonText('KHÔNG DUYỆT')
        setDisabledButton(true)
        break
      case 3:
        setButtonText('ĐÃ DUYỆT')
        setDisabledButton(true)
        break
      default:
        setButtonText('UPLOAD - CHỜ DUYỆT')
        break
    }
  }, [status])

  useEffect(() => {
    dispatch(fetchUser())
  }, [])

  return (
    <Spin spinning={loadingInfo}>
      <div className='page-cover'>
        <div className='body'>
          <div className='grid grid-cols-2 gap-4 body-row'>
            {defaultImage.map((item, index) => (
              <ChooseImage
                key={index}
                defaultImage={defaultImage}
                index={index}
                status={status}
                onFileChange={(url) => handleFileChange(index, url)}
                onDelete={() => handleDelete(defaultImage[index].value)}
                image={item.value}
              />
            ))}
          </div>
          <div className='button-cover'>
            <Button
              onClick={handleUpload}
              className='upload-button'
              type={disabledButton ? 'default' : 'primary'}
              disabled={disabledButton}
              loading={loading}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </Spin>
  )
}
