export const convertParamsAxios = (params: any) => {
  const queryString = Object.keys(params)
    .map((key) => {
      const value = params[key]
      if (Array.isArray(value)) {
        return value.map((item) => `${key}=${item}`).join('&')
      }
      return value !== undefined ? `${key}=${value}` : undefined
    })
    .filter((i) => i !== undefined)
    .join('&')
  return queryString
}
export const convertQueryStringToObject = (queryString) => {
  const params = {}

  if (queryString) {
    const pairs = queryString.split('&')

    pairs.forEach((pair) => {
      const [key, value] = pair.split('=')
      const newValue = Number.isInteger(Number(value)) ? parseInt(value, 10) : value
      if (key && value) {
        if (['PageSize', 'PageIndex', 'Keyword', 'groupId'].includes(key)) {
          params[key] = newValue
        }
        // eslint-disable-next-line no-prototype-builtins
        else if (params.hasOwnProperty(key)) {
          if (!Array.isArray(params[key])) {
            params[key] = [params[key]]
          }
          params[key].push(newValue)
        } else {
          params[key] = [newValue]
        }
      }
    })
  }

  return params
}
