import React, { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import Page404 from '../pages/Errors/404'
import Page403 from '../pages/Errors/403'
import Page500 from '../pages/Errors/500'
import ScrollToTop from 'components/SrollToTop'
import Loading from '../components/Loading'
import StylistSelectHair from 'pages/stylist/webview/selectHair'
import StylistSelectIntroduction from 'pages/stylist/webview/selectIntroduction'
import HairstyleCollection from 'pages/stylist/hairstyleCollection'

function DefaultRouter() {
  const routes = useRoutes([
    {
      path: '/stylist/webview/selectHair',
      element: <StylistSelectHair />
    },
    {
      path: '/stylist/webview/selectIntroduction',
      element: <StylistSelectIntroduction />
    },
    {
      path: '/stylist/hairstyleCollection',
      element: <HairstyleCollection />
    },
    {
      path: '/403',
      element: <Page403 />
    },
    {
      path: '/404',
      element: <Page404 />
    },
    {
      path: '/500',
      element: <Page500 />
    }
  ])
  return (
    <>
      <ScrollToTop />
      <Suspense fallback={<Loading />}>{routes}</Suspense>
    </>
  )
}

export default DefaultRouter
