import Cookies from 'js-cookie'

export const saveAuthData = (data) => {
  Cookies.set('AccessToken', data.AccessToken || '', { expires: 1, path: '/' })
  Cookies.set('RefreshToken', data.RefreshToken || '', { expires: 1, path: '/' })
  Cookies.set('IdToken', data.IdToken || '', { expires: 1, path: '/' })
  // localStorage.setItem('AccessToken', data.AccessToken || '')
  // localStorage.setItem('RefreshToken', data.RefreshToken || '')
  // localStorage.setItem('IdToken', data.IdToken || '')
}

export const removeAuthData = () => {
  Cookies.remove('AccessToken')
  Cookies.remove('RefreshToken')
  Cookies.remove('IdToken')
}
