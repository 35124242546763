import { author, authorV2, listMenu } from 'utils/requestManager/Author'

export const getUser = (params?: any) => {
  return authorV2.get('/api/user', params)
}

export const getPermissionUser = () => {
  return author.get(`/api/permissions/actions?domain=${process.env.REACT_APP_DOMAIN_PERMISSION}`)
}
export const getListMenuAccess = () => {
  return listMenu.get(`/api/menus?Keyword=employee-manager`)
}
