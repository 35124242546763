import { Result, Button } from 'antd'
import { Link } from 'react-router-dom'

export default function Page500() {
  return (
    <Result
      status='500'
      title='500'
      subTitle='Xin lỗi, hệ thống đang gặp lỗi. Vui lòng liên hệ team phát triển.'
      extra={
        <Button type='primary'>
          <Link to={'/'}>Quay lại trang chính</Link>
        </Button>
      }
    />
  )
}
