import { BrowserRouter } from 'react-router-dom'
import DefaultRouter from 'routes/DefaultRouter'

function DefaultLayout() {
  return (
    <BrowserRouter>
      <DefaultRouter />
    </BrowserRouter>
  )
}

export default DefaultLayout
