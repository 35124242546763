import { useEffect, useState } from 'react';
import HairStyle from './compoment/HairStyle';
import './hairstyleCollection.scss';
import back from 'images/ic_arrow_left_2x.jpeg'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { fetchUser } from 'redux/slices/userSlice'
import { getStylistCollection } from './api';

export default function HairstyleCollection() {
    const dispatch = useAppDispatch()
    const user = useAppSelector((state) => state.user)
    const [loading, setLoading] = useState(false)
    const [userStyleCollectionInfo, setUserStyleCollectionInfo] = useState([])

    const getInfo = async () => {
        try {
            setLoading(true)
            const response = await getStylistCollection(user.userInfo.userId)
            if (response?.data) {
                setUserStyleCollectionInfo(response?.data)
                return
            }
        } catch (error) {
            console.log('Lỗi không lấy được dữ liệu')
        }
        setLoading(false)
    }
    const handleOnClickBack = () => {
        const message = {
            type: 'UploadInfoStylist',
            message: 'press_back'
        }
        if (window?.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify(message))
        }
    }
    useEffect(() => {
        if (user.userInfo.listSalon.length) {
            getInfo()
            return
        }
    }, [user])

    useEffect(() => {
        dispatch(fetchUser())
    }, [])
    return (
        <div className='page-cover-2' >
            <div className='header'>
                {/* eslint-disable-next-line */}
                <img alt='img' className='icon' style={{ margin: '0', width: '20px' }} src={back} onClick={handleOnClickBack} onKeyDown={handleOnClickBack} />
                <span className='text pl-1'>Bộ sưu tập tư vấn kiểu tóc</span>
                <div style={{ margin: '0', width: '20px' }}></div>
            </div>
            <div className='space-bar' style={{ height: '20px', backgroundColor: '#F6F6F6' }}></div>
            <div className='body' >
                {userStyleCollectionInfo?.map((item, index) => (
                    <div key={index} style={{ display: userStyleCollectionInfo[index]?.hairStyleDetails.length === 0 ? "none" : "" }}>
                        <HairStyle
                            key={index}
                            styleData={userStyleCollectionInfo[index]}
                            index={index}
                            user={user}
                            getInfo={getInfo}
                        />
                    </div>
                ))}
            </div>
        </div >
    )
}
