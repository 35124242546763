import { useEffect, useState } from 'react'
import { Button, Modal, Spin, notification } from 'antd'
import icon from 'images/iconImg.png'
import ItemBox from './itemBox'
import { postStylistCollection } from '../../api'

const initListImage = ['', '', '', '']

const ListText = [
    { text: 'Ảnh chụp chính diện' },
    { text: 'Ảnh chụp đằng sau' },
    { text: 'Ảnh chụp bên trái' },
    { text: 'Ảnh chụp bên phải' }
]

type Props = {
    StyleIndex: number
    typeData: any
    user: any
    styleData: any
    getInfo
}

export default function StyleType({ StyleIndex, typeData, user, styleData, getInfo }: Props) {
    // map ra các biến thể
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [listImage, setListImage] = useState(initListImage)
    const [isChange, setIsChange] = useState(false)
    const [disabledButton, setDisabledButton] = useState(true)
    const [loading, setLoading] = useState(false)
    const [showText, setShowText] = useState(false)
    const handleFileChange = (index: number, url: string) => {
        const images = [...listImage]
        images[index] = url
        setListImage(images)
        setIsChange(true)
    }

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }
    const isNull = styleData.hairStyleDetails?.some((item) => item?.id)

    const handleCreate = async () => {
        if (loading) return
        const newData: any = styleData
        const newType: any = typeData
        newType.urlImage = listImage;
        newData.hairStyleDetails = [newType]
        delete newData.hairStyleDetails[0].id;
        if (!isNull) {
            delete newData.id;
        }

        const thisdata = newData
        try {
            setLoading(true)
            const response = await postStylistCollection(
                user.userInfo.userId, thisdata
            )
            if (response) {
                setDisabledButton(true)
                setIsChange(false)
            }
            notification.success({
                message: 'Thông báo',
                description: 'Cập nhật bộ sưu tập thành công'
            })
            setIsModalOpen(false)
            getInfo()
        } catch (error) {
            notification.error({
                message: 'Thông báo',
                description: 'Có lỗi xảy ra vui lòng thử lại sau!'
            })
            console.log(error, 'Lỗi không gửi được dữ liệu')
        }
        setLoading(false)
    }
    const handleUpload = async () => {
        if (loading) return
        const newData: any = styleData
        const newType: any = typeData
        newType.urlImage = listImage;
        newData.hairStyleDetails = [newType]
        const thisdata = newData
        try {
            setLoading(true)
            const response = await postStylistCollection(
                user.userInfo.userId, thisdata
            )
            if (response) {
                setDisabledButton(true)
                setIsChange(false)
            }
            notification.success({
                message: 'Thông báo',
                description: 'Cập nhật bộ sưu tập thành công'
            })
            setIsModalOpen(false)
            getInfo()

        } catch (error) {
            notification.error({
                message: 'Thông báo',
                description: 'Có lỗi xảy ra vui lòng thử lại sau!'
            })
            console.log(error, 'Lỗi không gửi được dữ liệu')
        }
        setLoading(false)
    }
    useEffect(() => {
        if (typeData?.urlImage?.length > 0) {
            setListImage(typeData?.urlImage)
        }
    }, [typeData])

    useEffect(() => {
        const isAnyEmpty = listImage.some((item) => !item)
        setShowText(isAnyEmpty)
        if (!isAnyEmpty && isChange) {
            setDisabledButton(false)
            return
        } else {
            setDisabledButton(true)
            return
        }
    }, [listImage])

    return (
        <div className='type'>
            {typeData?.urlImage?.length > 0 ? (
                <div className='type__box--notnull'
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            showModal()
                        }
                    }}
                    role='button'
                    tabIndex={0}


                    onClick={showModal}>
                    <div className='image-box'>
                        <img className='image' src={listImage[0]} alt='icon' />
                        <div className='button'>Sửa</div>
                    </div>
                </div>
            ) : (
                <div className='type__box--null'
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            showModal()
                        }
                    }}
                    role='button'
                    tabIndex={0}
                    onClick={showModal}>
                    <img className='type__box--icon' src={listImage[0].length > 0 ? listImage[0] : icon} alt='icon' />
                </div>
            )}

            <Modal open={isModalOpen} footer={false} onOk={handleOk} onCancel={handleCancel}>
                <div className='modal'>
                    <div className='header'>
                        <div className='style-title'>{styleData?.name}</div>
                        <div className='type-title'>{typeData?.name}</div>
                        <div className='waring' style={{ display: showText ? "" : "none" }}>Bạn cần upload đủ 4 ảnh của kiểu tóc </div>
                    </div>
                    <div className='body grid grid-cols-2 gap-4 body-row'>

                        {listImage?.map((item, index) => (
                            <Spin spinning={loading} key={index}>
                                <ItemBox
                                    key={index}
                                    index={index}
                                    image={listImage[index]}
                                    text={ListText[index].text}
                                    onFileChange={(url) => handleFileChange(index, url)}
                                    setLoading={setLoading}
                                    loading={loading}
                                />
                            </Spin>

                        ))}

                    </div>
                    <div className='footer'>
                        {typeData?.id?.length > 0 ?
                            (
                                <Button
                                    disabled={disabledButton}
                                    onClick={handleUpload}
                                    className={disabledButton ? 'save-button2' : 'save-button'}
                                >
                                    Lưu
                                </Button>
                            ) :
                            (
                                <Button
                                    disabled={disabledButton}
                                    onClick={handleCreate}
                                    className={disabledButton ? 'save-button2' : 'save-button'}
                                >
                                    Lưu
                                </Button>
                            )
                        }
                    </div>
                </div>
            </Modal>
            <div className='type__title'>
                <span className='type__title--text'>{typeData.name}</span>
            </div>
        </div>
    )
}
