import { notification, Spin } from 'antd';
import axios from 'axios';
import icon from 'images/iconImg.png';
import iconEdit from 'images/iconEdit.png';
import Cookies from 'js-cookie';
import { resizeImage } from 'pages/stylist/compoment/resizeImage';

type Props = {
  index: number;
  image: any;
  onFileChange: (url) => void;
  text: any;
  setLoading: any;
  loading: any;
};

export default function ItemBox({ text, index, onFileChange, image, setLoading, loading }: Props) {

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      try {
        setLoading(true);
        const resizedImage = await resizeImage(file, 1024, 1024, 0.5);
        await uploadImage(resizedImage);
      } catch (error) {
        console.error('Error resizing image:', error);
      } finally {
        setLoading(false);
      }
    } else {
      console.log('No image selected');
      notification.error({
        description: 'Vui lòng chọn ảnh',
        message: 'Thông báo',
      });
    }
  };

  const uploadImage = async (resizedImage: Blob) => {
    const AccessToken = Cookies.get('AccessToken') || '';
    if (loading) return;
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('photo', resizedImage, 'resized.jpg');
      const response = await axios.post(`${process.env.REACT_APP_POST_STAFF_IMAGE}/staff/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${AccessToken}`,
        },
      });
      if (response.data.data.image_url) onFileChange(response.data.data.image_url);
    } catch (error) {
      notification.error({
        description: 'Có lỗi xảy ra, vui lòng thử lại sau',
        message: 'Thông báo',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="item-box">
      {image?.length ? (
        <div className="item-notnull">
          <label className="edit-button">
            <img src={iconEdit} className="edit-icon" alt="" />
            <input className="custom-file-input" disabled={loading} type="file" accept=".heic, image/*" onChange={handleFileInputChange} />
          </label>
          <img className="image" src={image} alt="" />
        </div>
      ) : (
        <label className="item-null">
          <img className="icon" src={icon} alt="icon" />
          <span className="text">{text}</span>
          <input className="custom-file-input" disabled={loading} type="file" accept=".heic, image/*" onChange={handleFileInputChange} />
        </label>
      )}
    </div>
  );
}
