import getInstanceAxios from 'utils/requestManager/RequestGlobalConfig'

export const checkinViewRequest = getInstanceAxios(process.env.REACT_APP_LINK_NEW_CHECKIN_VIEWSERVICE)
export const checkinServiceRequest = getInstanceAxios(process.env.REACT_APP_LINK_NEW_CHECKIN_SERVICE)
export const checkinStaffViewRequest = getInstanceAxios(process.env.REACT_APP_LINK_NEW_CHECKIN_STAFFVIEW)

export const StaffRequestView = getInstanceAxios(process.env.REACT_APP_LINK_STAFF_SERVICE_VIEW)
export const StaffRequest = getInstanceAxios(process.env.REACT_APP_GET_STAFF_SERVICE)

export const PostImage = getInstanceAxios(process.env.REACT_APP_POST_STAFF_IMAGE)

export const StylistCollection = getInstanceAxios(process.env.REACT_APP_STYLIST_COLLECTION)
